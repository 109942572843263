const ONE_MINUTE = 60000
const ONE_HOUR = 3600000
const ONE_DAY = 86400000
const SINGULAR = 1

const getPluralizedText = (entityName?: string, count?: number) => {
  if (!entityName || !count) {
    return 'Last won less than a minute ago'
  }

  return `Last won ${count} ${entityName}${count !== SINGULAR ? 's' : ''} ago`
}
export default function getLastWinText(currentTime: number, lastWinAt: number) {
  const timeSinceLastWin = currentTime - lastWinAt
  if (timeSinceLastWin < ONE_MINUTE) {
    return getPluralizedText()
  } else if (timeSinceLastWin < ONE_HOUR) {
    const minutes = Math.floor(timeSinceLastWin / ONE_MINUTE)

    return getPluralizedText('minute', minutes)
  } else if (timeSinceLastWin < ONE_DAY) {
    const hours = Math.floor(timeSinceLastWin / ONE_HOUR)

    return getPluralizedText('hour', hours)
  } else {
    const days = Math.floor(timeSinceLastWin / ONE_DAY)

    return getPluralizedText('day', days)
  }
}

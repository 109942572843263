import { Link } from 'components/Link'
import type { Invitations } from '@patrianna/shared-patrianna-types/store/AccountInvitationsModule'
import CopyToClipboardInput from 'components/CopyToClipboardInput'
import InvitedFriendInfo from 'components/InvitedFriendInfo'
import classes from './styles.module.scss'
import ROUTES from 'temp/routes.json'
import { useTranslation } from 'context/translationContext'
import RichText from 'components/RichText'
import useShareUrl from 'hooks/useShareUrl'

type Props = {
  accountId: string
  invitations: Invitations
}

export default function ReferFriendSwimlane(props: Props) {
  const t = useTranslation()
  const shareUrl = useShareUrl(props.accountId)

  return (
    <div className={classes.root} data-test='sc-invite-friends-swimlane'>
      <div className={classes.information}>
        <h3>{t('componentReferFriendSwimlane.title')}</h3>
        <RichText
          field={t('componentReferFriendSwimlane.information')}
          components={{
            paragraph: ({ children }) => <p className={classes.infoText}>{children}</p>,
            strong: ({ children }) => <span className={classes.colorRed}>{children}</span>,
            em: ({ children }) => <span className={classes.colorSecondary}>{children}</span>,
          }}
        />{' '}
        <Link href={ROUTES.REFER_FRIEND}>{t('componentReferFriendSwimlane.link')}</Link>
        {props.invitations && (
          <InvitedFriendInfo invitations={props.invitations} className={classes.invitedFriendBlock} />
        )}
        {/* SWIMLANE */}
        <CopyToClipboardInput className={classes.copyClipboardInput} shareNative inputText={shareUrl} />
      </div>

      <div className={classes.imgHolder}>
        <img src={`${process.env.IMG_ORIGIN}/dialogs/invite-friend.png`} alt='dont miss it' />
      </div>
    </div>
  )
}

import type { SwimlaneCategory } from '@patrianna/shared-patrianna-types/store/GameModule'
import categoriesConfig from 'temp/categories.json'

export const getCategories = (hiddenUrls: string[] = [], productLayoutCategories?: SwimlaneCategory[]) => {
  const availableCategories = categoriesConfig.reduce((acc, category) => {
    const homeCategory = category.route === '/home'
    const hiddenCategory = !hiddenUrls.includes(category.route)
    const existsCategoriesInProductLayoutCategories = productLayoutCategories?.some(
      (productLayoutCategory) => productLayoutCategory.route === `${category.route}/`
    )

    if ((existsCategoriesInProductLayoutCategories && hiddenCategory) || homeCategory) {
      acc.push(category)
    }

    return acc
  }, [])

  return availableCategories
}

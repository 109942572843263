import { useAppSelector } from 'store/hooks'
import { sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'
import {
  getGoldJackpotTotalsSelector,
  getSweepstakeJackpotTotalsSelector,
  getWinsJackpotSelector,
} from 'store/modules/jackpots/selectors'
import HorizontalScroll from 'containers/HorizontalScroll'
import JackpotsListItem from 'containers/Jackpots/components/JackpotListItem'
import classes from './styles.module.scss'

const isJackpotEnabled = process.env.JACKPOT_ENABLED

export default function Jackpots() {
  const goldJackpotTotals = useAppSelector(getGoldJackpotTotalsSelector)
  const sweepStakeJackpotTotals = useAppSelector(getSweepstakeJackpotTotalsSelector)
  const scEnabled = useAppSelector(sweepstakeEnabledSelector)
  const jackpotWins = useAppSelector(getWinsJackpotSelector)

  if (!isJackpotEnabled) {
    return null
  }

  return (
    <HorizontalScroll classNameContainer={classes.root}>
      {scEnabled && <JackpotsListItem jackpotTotal={sweepStakeJackpotTotals} jackpotWins={jackpotWins.SC} sc />}
      <JackpotsListItem jackpotTotal={goldJackpotTotals} jackpotWins={jackpotWins.GC} />
    </HorizontalScroll>
  )
}

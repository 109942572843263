import cx from 'classnames'
import type { Invitations } from '@patrianna/shared-patrianna-types/store/AccountInvitationsModule'
import classes from './styles.module.scss'
import { useTranslation } from 'context/translationContext'

type InvitedFriendInfoProps = {
  invitations: Invitations
  className?: string
}

function InvitedFriendInfo({ invitations, className }: InvitedFriendInfoProps) {
  const t = useTranslation()

  const invitationsInfo: Array<{ title: string; key: string; SC: boolean }> = [
    {
      title: t('pageReferFriend.refer_invited'),
      key: 'invited',
      SC: false,
    },
    {
      title: t('pageReferFriend.refer_qualified_level_1'),
      key: 'invitedLvl1',
      SC: false,
    },
    {
      title: t('pageReferFriend.refer_qualified_level_2'),
      key: 'invitedLvl2',
      SC: false,
    },
    {
      title: t('pageReferFriend.refer_sc_rewards'),
      key: 'scAmount',
      SC: true,
    },
  ]

  return (
    <ul className={cx(classes.invitationList, className)}>
      {invitationsInfo.map((invitationInfo) => (
        <li className={classes.invitationItem} key={invitationInfo.title}>
          <p className={classes.invitationTitle}>{invitationInfo.title}</p>
          <p className={classes.invitationData}>
            {invitationInfo.SC ? `${t('commonModal.sc_text')} ` : ''}
            {invitations[invitationInfo.key as keyof Invitations]}
          </p>
        </li>
      ))}
    </ul>
  )
}

export default InvitedFriendInfo

import type { ReactNode } from 'react'
import cx from 'classnames'
import { B2Carousel } from '@patrianna/b2-carousel/src'
import { useAppSelector } from 'store/hooks'
import { isAppInitializedSelector } from 'store/modules/appConfig/selectors'
import type { Props as HorizontalScrollProps } from 'containers/HorizontalScroll'
import classes from './styles.module.scss'
import RainbowBackdrop from 'src/components/RainbowBackdrop'
import { getDialogVisibilitySelector } from 'src/store/modules/dialog/selectors'

type Props = {
  children: ReactNode
  enableBackdrop?: boolean
  showPagination?: boolean
  showArrows?: boolean
  className?: string
  scrollClassName?: string
  autoScrollTimeInterval?: number
} & HorizontalScrollProps

export default function BannerCollection(props: Props) {
  const appInitialized = useAppSelector(isAppInitializedSelector)
  const dialogVisible = useAppSelector(getDialogVisibilitySelector)

  return (
    <B2Carousel
      autoScrollInterval={props.autoScrollTimeInterval}
      showArrows={props.showArrows}
      autoScroll={!dialogVisible}
      showPagination={props.showPagination}
      classNameContainer={cx(classes.root, props.scrollClassName)}
      scrolledItemDataAttr='data-banner'
      BackDropNode={props.enableBackdrop && appInitialized && <RainbowBackdrop className={classes.backdropRainbow} />}
      className={cx(classes.container, props.className, props.enableBackdrop && classes.backdrop)}
      {...props}
    >
      {props.children}
    </B2Carousel>
  )
}

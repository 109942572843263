import type { PromoHighlightsProps } from '../..'
import classes from './styles.module.scss'

type Props = {
  promotion: PromoHighlightsProps
}

export default function PromoHighlight(props: Props) {
  const { imageUrl, imageAlt, promoUrl } = props.promotion.data

  return (
    <a href={promoUrl}>
      <div className={classes.imageContainer}>
        <img className={classes.image} src={imageUrl} alt={imageAlt} />
      </div>
    </a>
  )
}

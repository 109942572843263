import classes from './styles.module.scss'
import { useMemo } from 'react'
import { AnimatedDigit } from 'components/TransitionNumber/AnimatedDigit'

type Props = {
  numberToRoll: string
  height: number
}

export default function RollNumber({ numberToRoll, height }: Props) {
  const animateToNumbersArr = useMemo(
    () => Array.from(numberToRoll, Number).map((x, idx) => (isNaN(x) ? numberToRoll[idx] : x)),
    [numberToRoll]
  )

  return (
    <div className={classes.root}>
      {animateToNumbersArr.map((digit, index) => {
        if (typeof digit === 'string') {
          return <span key={index}>{digit}</span>
        } else {
          return (
            <div key={index} className={classes.cell} style={{ height: `${height}px` }}>
              <AnimatedDigit height={height} number={digit} />
            </div>
          )
        }
      })}
    </div>
  )
}

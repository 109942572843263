import cx from 'classnames'
import classes from './styles.module.scss'
import { useTranslation } from 'context/translationContext'
import { useIsMobile } from '@patrianna/shared-hooks'

export default function IOS() {
  const t = useTranslation()
  const isMobile = useIsMobile()

  return (
    <div className={cx(classes.text, classes.textCenter, classes.withArrow)}>
      {isMobile ? t('snapBarPWA.install_this_web_app_iPhone') : t('snapBarPWA.install_this_web_app_desktop')}{' '}
      <span className={classes.wrapSvg}>
        <svg height='18' width='18' viewBox='0 0 50 50'>
          <path
            fill='none'
            className={classes.pathSvg}
            strokeLinecap='round'
            strokeMiterlimit='10'
            strokeWidth='2'
            d='M17 10l8-8 8 8M25 32V2.333'
          />
          <path fill='none' d='M0 0h50v50H0z' />
          <path
            d='M17 17H8v32h34V17h-9'
            className={classes.pathSvg}
            fill='none'
            strokeLinecap='round'
            strokeMiterlimit='10'
            strokeWidth='2'
          />
        </svg>
      </span>{' '}
      {isMobile ? t('snapBarPWA.add_to_home_screen') : t('snapBarPWA.add_to_dock')}
    </div>
  )
}

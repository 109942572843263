import BannerTile from 'components/BannerTile'
import ROUTES from 'temp/routes.json'
import { generateBannerUrl } from '../utils/generateBannerUrl'

export default function LoyaltyProgramBanner() {
  const bannerUrl = generateBannerUrl('banners/HP_LC_launch_v1/source.png')

  return (
    <BannerTile
      url={ROUTES.LOYALTY_PROGRAM}
      src={bannerUrl}
      alt='loyalty program banner'
      dataTest='loyalty-program-banner'
    />
  )
}

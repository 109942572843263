import type { Currencies } from '@patrianna/shared-patrianna-types/store/CurrenciesModule'

export const getBuilderFilteredData = (items: any[], country: string, enabledCurrency: Currencies) => {
  const filteredData = items.filter((item) => {
    const currencyCondition = item.data.currencyName === enabledCurrency || item.data.currencyName === 'ALL'
    const countryCondition = !item.data.country || item.data.country === country || item.data.country === 'ALL'

    return currencyCondition && countryCondition
  })

  return filteredData
}

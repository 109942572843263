import FirstTimeSCOffers from './FirstTimeSCOffers'
import { useAuth } from 'app/context/auth'

function Offers() {
  const { isScEnabled } = useAuth()

  if (isScEnabled) {
    return <FirstTimeSCOffers />
  }

  return null
}

export default Offers

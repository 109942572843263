import { useState, useEffect } from 'react'
import Button from 'components/Button'
import { useIsMobile } from 'app/context/detectMobile'
import { captureMessage } from '@sentry/nextjs'
import classes from './styles.module.scss'
import { useTranslation } from 'app/context/translations'
import { trackEvent } from 'config/analytic'
import { usePageViewTracker } from '@patrianna/shared-hooks'

type TSharingNativeMobile = {
  title?: string
  text?: string
  url?: string
  inJackpot?: boolean
  customEvent?: string
}

export const SharingNativeMobile = ({ url, title, text, customEvent, inJackpot }: TSharingNativeMobile) => {
  const isMobile = useIsMobile()
  const [isShareAPIAvailable, setShareAPIAvailability] = useState(false)
  const t = useTranslation()

  useEffect(() => {
    if (navigator?.share && isMobile) {
      setShareAPIAvailability(true)
    }
  }, [isMobile])

  usePageViewTracker(() => {
    if (inJackpot && isShareAPIAvailable) {
      trackEvent('jackpotwin_share_show')
    }
  })

  if (!isShareAPIAvailable) {
    return null
  }

  const handleClick = async () => {
    trackEvent(customEvent || 'clicked_on_referfriend_sharelink')
    const shareData = {
      ...(title && { title }),
      ...(text && { text }),
      ...(url && { url }),
    }

    try {
      await navigator.share(shareData)
    } catch (err) {
      if (!err.toString().includes('AbortError')) {
        captureMessage(`Error in SharingNativeMobile: ${err?.message}; Agent: ${navigator?.userAgent}`)
      }
    }
  }

  return (
    <Button onClick={handleClick} variant='contained' size='small' className={classes.shareButton}>
      <img src={`${process.env.IMG_ORIGIN}/dialogs/share.png`} alt='Share' />
      {t('common.share_link')}
    </Button>
  )
}

'use client'

import type { SwimlaneCategory } from '@patrianna/shared-patrianna-types/store/GameModule'
// import SeoComponent from 'pageContainers/Home/components/Seo/SeoComponent'
import Categories from 'containers/Categories'
import GameProviders from 'containers/GameProviders'
import HomeBanners from 'containers/HomeBanners'
import Jackpots from 'containers/Jackpots'
import { getNumberOfPreloadedItems } from 'utils/games'
import classes from './styles.module.scss'
import ROUTES from 'temp/routes.json'
import SlotsRow from '../SlotsRow'
import ReferFriendSwimlane from 'containers/ReferFriendSwimlane'
import Highlights from 'containers/Highlights'
import { useCountryConfig } from 'app/context/country'
import useIsLandscape from 'hooks/useIsLandscape'

type Props = {
  categories: SwimlaneCategory[]
  isMobileUserAgent: boolean
  cookies: string
}

export default function HomeContent(props: Props) {
  const { categories, isMobileUserAgent, cookies } = props
  const { exclusiveCategoryFeature } = useCountryConfig()
  const isLandscape = useIsLandscape()
  const hiddenExclusiveCategory = !exclusiveCategoryFeature.enabled && ROUTES.EXCLUSIVE_GC

  return (
    <div className={classes.pageContent}>
      <HomeBanners cookies={cookies} isMobileUserAgent={isMobileUserAgent} />

      <Categories hiddenUrls={[ROUTES.RECENTLY_PLAYED, hiddenExclusiveCategory]} productLayoutCategories={categories} />

      {categories.map((category) => {
        if (category.route === `${ROUTES.EXCLUSIVE_GC}/` && !exclusiveCategoryFeature.enabled) {
          return null
        }

        if (category.type === 'providers') {
          return (
            <GameProviders
              key='category-providers'
              providers={category}
              className={classes.title}
              isMobileUserAgent={isMobileUserAgent}
            />
          )
        }

        if (category.type === 'jackpot') {
          return <Jackpots key='category-jackpot' />
        }

        if (category.title === 'Refer a Friend') {
          // це тимчасово, поки бекенд не додасть type: 'refer-friend' (бекенд таска 1730)
          return <ReferFriendSwimlane key='refer-a-friend' />
        }

        if (category.title === 'Highlights') {
          // це тимчасово, поки бекенд не додасть type: 'highlights' (бекенд таска 1815)
          return <Highlights title={category.title} key='highlights' />
        }

        const numberOfPreLoadedItems = getNumberOfPreloadedItems(category, isMobileUserAgent, isLandscape)

        return (
          <SlotsRow
            games={category.products}
            title={category.title}
            iconImg={category.icon}
            count={category.products.length}
            lines={category.lines}
            type={category.type}
            style={category.style}
            url={`${category.route}`}
            isMobileUserAgent={isMobileUserAgent}
            key={`category-${category.index}`}
            numberOfPreLoadedItems={numberOfPreLoadedItems}
            className={classes.title}
          />
        )
      })}
      {/* <SeoComponent /> */}
    </div>
  )
}

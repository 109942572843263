import { useAppSelector, useAppDispatch } from 'store/hooks'
import { getUserAccountIdSelector } from 'store/modules/user/selectors'
import SCInviteFriends from './SCInviteFriends'
import { getAccountInvitationsSelector } from 'store/modules/accountInvitations/selectors'
import { useEffect } from 'react'
import { getAccountInvitations } from 'store/modules/accountInvitations/actions'
import { useCountryConfig } from 'app/context/country'
import { useAuth } from 'app/context/auth'

export default function ReferFriendSwimlane() {
  const dispatch = useAppDispatch()
  const accountId = useAppSelector(getUserAccountIdSelector)
  const { invitations } = useAppSelector(getAccountInvitationsSelector)
  const { referFriendFeature } = useCountryConfig()
  const { isScEnabled, isLoggedIn } = useAuth()

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getAccountInvitations())
    }
  }, [isLoggedIn])

  if (!referFriendFeature.enabled || !isLoggedIn || !isScEnabled) {
    return null
  }

  return <SCInviteFriends accountId={accountId} invitations={invitations} />
}

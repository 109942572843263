import { useState, useEffect } from 'react'
import { intlnumberFormat, intlnumberFormatDouble } from '@patrianna/shared-utils'

type ValueTransitionOptions = {
  prevAmount: number
  amount: number
  updateInterval: number
  updatedAt: number
  inView: boolean
  sc: boolean
}

const VELOCITY = 1000
const useValueTransition = ({ prevAmount, amount, updateInterval, updatedAt, inView, sc }: ValueTransitionOptions) => {
  const [currentValue, setCurrentValue] = useState(prevAmount)

  useEffect(() => {
    // if element not in view stop transition
    if (!inView) {
      return
    }

    const totalSteps = Math.floor((updateInterval * 60 * 1000) / VELOCITY)
    // Calculate the step value for each step of transition
    const stepValue = (amount - prevAmount) / totalSteps
    // Calculate the number of steps that have passed since updatedAt
    const passedSteps = Math.max(0, Math.floor((new Date().getTime() - updatedAt) / VELOCITY))
    // Handle cases where transition is not needed or can't be performed
    if (!prevAmount || prevAmount === amount || passedSteps >= totalSteps || !updatedAt) {
      setCurrentValue(amount)

      return
    }

    // Pattern for adjusting step values. It is done in pairs not get value higher
    const pattern = [1.1, 0.9, 1.2, 0.8, 1.3, 0.7, 1.4, 0.6, 1.5, 0.5]

    // Calculate array of transition values using the pattern for each transition step
    const mapValues = Array.from(Array(totalSteps)).reduce(
      (acc, i, index) => {
        if (index > 0) {
          const patternIndex = index % pattern.length
          const patternMultiplier = pattern[patternIndex]
          acc.push(acc[index - 1] + stepValue * patternMultiplier)

          return acc
        } else {
          return acc
        }
      },
      [prevAmount]
    )

    if (passedSteps < totalSteps) {
      setCurrentValue(mapValues[passedSteps])
    }

    const interval = setInterval(() => {
      let currentStep = Math.max(0, Math.floor((new Date().getTime() - updatedAt) / VELOCITY))
      if (currentStep < totalSteps) {
        setCurrentValue(mapValues[currentStep])
      } else {
        setCurrentValue(amount)
        clearInterval(interval)
      }
    }, VELOCITY)

    return () => {
      clearInterval(interval)
    }
  }, [inView, amount, updatedAt, prevAmount, updateInterval])

  return sc
    ? intlnumberFormatDouble(currentValue, 'en-US', { maximumFractionDigits: 2 })
    : intlnumberFormat(Math.trunc(currentValue))
}

export default useValueTransition

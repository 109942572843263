import EXTERNAL_LINKS from 'temp/externalLinks.json'
import { getCookie } from '@patrianna/shared-utils/cookie'
import BannerCollection from 'containers/BannerCollection'
import DailyReward from './banners/DailyReward'
import Offers from './banners/Offers'
import PromoBannersFromBuilder from './banners/PromoBannersFromBuilder'
import RegisterBanner from './banners/RegisterBanner'
import SCbanner from './banners/SCbanner'
import SocialBanner from './banners/SocialBanner'
import WelcomeEmail from './banners/WelcomeEmail'
import LoyaltyProgramBanner from './banners/LoyaltyProgramBanner'
import { useAuth } from 'app/context/auth'
import { useCountryConfig } from 'app/context/country'
import { IS_USER_OPENED_SC_RULES } from 'app/constants/cookies'

type Props = {
  cookies: string
  isMobileUserAgent: boolean
}

export default function HomeBanners(props: Props) {
  const isDailyRewardEnabled = process.env.DAILY_REWARDS_ENABLED
  const { isLoggedIn, isScEnabled, isWelcomeEmailSubmited } = useAuth()
  const isSCBannerClicked = typeof document !== 'undefined' && getCookie(IS_USER_OPENED_SC_RULES, document.cookie)
  const showSCbanner = isScEnabled && !isSCBannerClicked
  const { bannersFeature } = useCountryConfig()

  return (
    <BannerCollection
      enableBackdrop
      autoScrollTimeInterval={5_000}
      showArrows={!props.isMobileUserAgent}
      showPagination={props.isMobileUserAgent}
    >
      {bannersFeature.enabled && (
        <>
          {isLoggedIn && <LoyaltyProgramBanner />}
          <Offers />
          {!isLoggedIn && <RegisterBanner />}
          {!isLoggedIn && isDailyRewardEnabled && <DailyReward />}
          {!isLoggedIn && <LoyaltyProgramBanner />}
          {!isScEnabled && <SocialBanner imgKey='facebook' link={EXTERNAL_LINKS.visitFB} />}
          {!isScEnabled && <SocialBanner imgKey='instagram' link={EXTERNAL_LINKS.instagram} />}
          {showSCbanner && <SCbanner />}
          {isScEnabled && !isWelcomeEmailSubmited && <WelcomeEmail />}
          <PromoBannersFromBuilder />
        </>
      )}

      {!bannersFeature.enabled && (
        <>
          {!isLoggedIn && <RegisterBanner />}
          {isDailyRewardEnabled && <DailyReward />}
          {showSCbanner && <SCbanner />}
          {isLoggedIn && <PromoBannersFromBuilder />}
        </>
      )}
    </BannerCollection>
  )
}

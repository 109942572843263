import { isBrowser } from '@patrianna/shared-utils/helpers'

// replace with game.route
export const convertTitleToUrl = (str) => {
  return str
    .toString()
    .replace(/\s-\s/g, ' ')
    .replace(/[&/\\#,+()$~%.'":*?<>{}]/g, '')
    .replace(/\s+/g, '-')
    .toLowerCase()
}

export const getQueryFromRoute = (route) => {
  const regex = /\[.+?\]/g
  // get all dynamic query params from url

  return route.match(regex)
}

export const generateShareUrlWithUserId = (accountId) => {
  if (isBrowser()) {
    return `${window.location.origin}/${accountId ? `?r=${encodeURIComponent(accountId)}` : ''}`
  }

  return ''
}

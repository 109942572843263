'use client'

import { usePathname } from 'next/navigation'
import type { SwimlaneCategory } from '@patrianna/shared-patrianna-types/store/GameModule'
import HorizontalScroll from 'containers/HorizontalScroll'
import { useIsMobile } from 'app/context/detectMobile'
import { useTranslation } from 'context/translationContext'
import { getCategories } from 'containers/Categories/utils'
import Pill from 'components/Pill'
import classes from './styles.module.scss'
import { useRouter } from 'app/context/navigation'

type CategoriesProps = {
  hiddenUrls?: string[]
  productLayoutCategories?: SwimlaneCategory[]
}

type Category = {
  route: string
  title: string
  icon: string
}

export default function Categories({ hiddenUrls, productLayoutCategories }: CategoriesProps) {
  const { push } = useRouter()
  const pathname = usePathname()
  const isMobile = useIsMobile()
  const t = useTranslation()

  const categories = getCategories(hiddenUrls, productLayoutCategories)

  const isCurrentPage = (path: string): boolean => path === pathname

  const autoScrollCategories = (container: HTMLElement) => {
    const items = document.querySelectorAll('[data-pill]')

    if (items?.length && container) {
      let currentItem: Element
      items.forEach((item) => {
        if (item.getAttribute('data-pill') === pathname) {
          currentItem = item
        }
      })

      if (currentItem) {
        const scrollToItemLength = (currentItem.parentNode as HTMLDivElement)?.offsetLeft - container.scrollLeft

        container.scrollBy({ top: 0, left: scrollToItemLength - 1, behavior: 'smooth' })
      }
    }
  }

  return (
    <HorizontalScroll
      showArrows={!isMobile}
      customScroll={autoScrollCategories}
      pathname={pathname}
      scrolledItemDataAttr='data-pill'
      className={classes.root}
      classNameContainer={classes.categories}
    >
      {categories.map((category: Category) => (
        <Pill
          key={category.route}
          selected={isCurrentPage(category.route)}
          label={t(`common.${category.title}`)}
          onClick={() => {
            push(category.route)
          }}
          icon={category.icon}
          data-pill={category.route}
        />
      ))}
    </HorizontalScroll>
  )
}

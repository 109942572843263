import BannerTile from 'components/BannerTile'
import ROUTES from 'temp/routes.json'
import { generateBannerUrl } from '../utils/generateBannerUrl'
import { setCookie } from '@patrianna/shared-utils/cookie'
import { IS_USER_OPENED_SC_RULES } from 'app/constants/cookies'

function SCbanner() {
  const bannerUrl = generateBannerUrl('banners/HP_No_Purchase_Necessary/source.png')

  const onClickHandler = () => {
    setCookie(IS_USER_OPENED_SC_RULES, true, { path: '/', expires: new Date(Date.now() + 31536000000) })
  }

  return (
    <BannerTile
      url={`${ROUTES.SWEEPSTAKE_RULES}#how-to-collect-sweepstake-coins`}
      onClick={onClickHandler}
      src={bannerUrl}
      alt='hp-free-banner'
      dataTest='sc-banner'
    />
  )
}

export default SCbanner

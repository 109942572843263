import type { ReactNode } from 'react'
import cx from 'classnames'
import { Direction, Slide } from '@patrianna/core-components'
import classes from './styles.module.scss'
import Close from './Close'
import RainbowBorder from 'src/components/RainbowBorder'

type Props = {
  children: ReactNode
  className?: string
  closeHandler: () => void
}
export default function MessageLayout(props: Props) {
  return (
    <Slide direction={Direction.Up} in className={classes.slide}>
      <div className={cx(classes.root, props.className)}>
        <RainbowBorder height='auto' width='100%' borderRadius='6px'>
          <div className={classes.rainbowBox}>
            <div className={classes.content}>{props.children}</div>
            <Close onClick={props.closeHandler} />
          </div>
        </RainbowBorder>
      </div>
    </Slide>
  )
}

import Image from 'next/image'
import { Link } from 'components/Link'
import type { ReactElement } from 'react'
import cx from 'classnames'
import { useMediaQuery } from '@patrianna/shared-hooks'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import type { JackpotTotals, JackpotTotalsCode } from 'store/types/JackpotsModule'
import { sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'
import classes from './styles.module.scss'
import ROUTES from 'temp/routes.json'
import { useTranslation } from 'src/context/translationContext'
import Button from 'components/Button'
import { getSweepstakeJackpotOptInSelector, isAccountJackpotInfoLoaded } from 'store/modules/jackpots/selectors'
import { openDialog } from 'store/modules/dialog/actions'
import TransitionNumber from 'components/TransitionNumber'
import LastWin from '../LastWin'
import { trackEvent } from 'config/analytic'
import WinBadge from 'containers/Jackpots/components/WinBadge'

type Props = {
  sc?: boolean
  jackpotTotal: JackpotTotals[]
  jackpotWins: {
    [type in JackpotTotalsCode]?: number
  }
}

const CELL_HEIGHT = 38
const MOBILE_CELL_HEIGHT = 24
const GRAND_MOBILE_CELL_HEIGHT = 28
const GRAND_CELL_HEIGHT = 48

export default function JackpotsListItem(props: Props): ReactElement | null {
  const dispatch = useAppDispatch()
  const isMobile = useMediaQuery('sm')
  const { sc, jackpotTotal } = props
  const isScEnabled = useAppSelector(sweepstakeEnabledSelector)
  const t = useTranslation()
  const isOptedIn = useAppSelector(getSweepstakeJackpotOptInSelector)
  const accountJackpotInfoLoaded = useAppSelector(isAccountJackpotInfoLoaded)

  const handleOptInClick = (trackEvt: boolean) => {
    if (trackEvt) {
      trackEvent('optintojackpots_swimlane_optin')
    }
    dispatch(openDialog({ modalName: 'JACKPOTS_SWEEPSTAKE_INFO_DIALOG' }))
  }

  const getHeight = (code: JackpotTotalsCode) => {
    return code === 'grand'
      ? isMobile
        ? GRAND_MOBILE_CELL_HEIGHT
        : GRAND_CELL_HEIGHT
      : isMobile
      ? MOBILE_CELL_HEIGHT
      : CELL_HEIGHT
  }

  return (
    <div className={sc ? cx(classes.listItem, classes.sc) : cx(classes.listItem, classes.gc)}>
      <div className={classes.title}>
        <Image
          src={`${process.env.IMG_ORIGIN}/jackpots/${sc ? 'sc' : 'gc'}.png?v2`}
          alt='reward'
          className={classes.currencyImage}
          priority
          width={'160'}
          height={'40'}
        />
      </div>
      <ul className={classes.innerList}>
        {jackpotTotal?.map((item, index) => (
          <li className={cx(classes.ilItem, classes[item.code])} key={index}>
            <div className={classes.ilTitle}>
              {sc ? t(`jackpots_home_banners.sc_${item.code}`) : t(`jackpots_home_banners.gc_${item.code}`)}
            </div>
            {item.lastWinAt && <LastWin lastWinAt={item.lastWinAt} />}
            <TransitionNumber classNames={classes.ilQnt} height={getHeight(item.code)} jackpotRowInfo={item} sc={sc} />
            <div className={classes.ilCaption}>
              {sc ? t('jackpots_home_banners.sweepstake_coins') : t('jackpots_home_banners.gold_coins')}
            </div>
            {props.jackpotWins?.[item?.code] && (
              <WinBadge currency={sc ? 'SC' : 'GC'} code={item.code} count={props.jackpotWins[item?.code]} />
            )}
          </li>
        ))}
      </ul>
      <div className={classes.footer}>
        <Link
          href={isScEnabled && sc ? ROUTES.JACKPOT_PROMOTIONS_SC : ROUTES.JACKPOT_PROMOTIONS}
          className={classes.link}
        >
          {t('jackpots_home_banners.more')}
        </Link>
        {accountJackpotInfoLoaded && !isOptedIn && sc && (
          <Button variant='contained' className={classes.optInButton} onClick={() => handleOptInClick(true)}>
            {t('common.opt_in')}
          </Button>
        )}
        {isOptedIn && sc && (
          <Button variant='outlined' className={classes.optedInButton} onClick={() => handleOptInClick(false)}>
            <span className={classes.optInButtonText}>{t('common.opt_out')}</span>
          </Button>
        )}
      </div>
    </div>
  )
}

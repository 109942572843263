import { IconButton } from '@patrianna/core-components'
import { Close } from 'mdi-material-ui'
import classes from './styles.module.scss'

type Props = {
  onClick: () => void
}

export default function CloseButton(props: Props) {
  return (
    <IconButton onClick={props.onClick} className={classes.close}>
      <Close className={classes.icon} />
    </IconButton>
  )
}

import { useState, useEffect } from 'react'
import { generateShareUrlWithUserId } from 'utils/url'

export default function useShareUrl(accountId: string) {
  const [shareUrl, setShareUrl] = useState('')

  useEffect(() => {
    setShareUrl(generateShareUrlWithUserId(accountId))
  }, [accountId])

  return shareUrl
}

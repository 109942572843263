import cx from 'classnames'
import { Link } from 'components/Link'
import type { SwimlaneCategory } from '@patrianna/shared-patrianna-types/store/GameModule'
import classes from './styles.module.scss'

type GameProvidersListProps = {
  providers: SwimlaneCategory
  containerClassName?: string
}

export default function GameProvidersList({ providers, containerClassName }: GameProvidersListProps) {
  return (
    <ul className={cx(classes.root, containerClassName)}>
      {providers.products.map((provider) => {
        return (
          <li className={classes.listItem} key={provider.code}>
            <Link href={providers.route + provider.code} className={classes.imgWrapper}>
              <img
                src={`${process.env.IMG_ORIGIN}/game_providers/${provider.code}_v2.svg`}
                alt={provider.title}
                className={classes.img}
                loading='lazy'
              />
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

import { useCopyClipboard } from '@patrianna/shared-hooks'
import cx from 'classnames'
import Button from 'components/Button'
import classes from './styles.module.scss'
import { useTranslation } from 'context/translationContext'
import { trackEvent } from 'config/analytic'
import { Show } from 'components/Show'
import { SharingNativeMobile } from 'components/SharingNativeMobile'

type Props = {
  inputText: string
  copyCustomEvent?: string
  shareCustomEvent?: string
  className?: string
  shareNative?: boolean
}

export default function CopyToClipboardInput(props: Props) {
  const [isCopied, setClipboard] = useCopyClipboard(props.inputText)
  const t = useTranslation()

  const handleClick = () => {
    if (!isCopied) {
      trackEvent(props?.copyCustomEvent || 'clicked_on_referfirend_copylink')
    }
    setClipboard()
  }

  return (
    <div className={cx(classes.root, props.className)}>
      <div className={classes.buttonsContainer}>
        <Button
          onClick={handleClick}
          variant='contained'
          size='small'
          className={classes.button}
          data-test='invite-friends-copy-link-button'
        >
          <Show when={!isCopied}>
            <img src={`${process.env.IMG_ORIGIN}/dialogs/copy.png`} alt='copy icon' />
            {t('common.copy_link')}
          </Show>
          <Show when={isCopied}>✓ {t('common.copied')}</Show>
        </Button>
        <Show when={props.shareNative}>
          <SharingNativeMobile url={props.inputText} customEvent={props.shareCustomEvent} />
        </Show>
      </div>
      <div className={classes.linkHolder}>{props.inputText}</div>
    </div>
  )
}

import type { JackpotTotals } from 'store/types'
import { useAppSelector } from 'store/hooks'
import { getUpdateInterval } from 'store/modules/jackpots/selectors'
import { useInView } from 'react-intersection-observer'
import useValueTransition from 'components/TransitionNumber/useValueTransition'
import RollNumber from 'components/TransitionNumber/RollNumber'

type Props = {
  jackpotRowInfo: JackpotTotals
  sc?: boolean
  height: number
  classNames?: string
}
function TransitionNumber({ jackpotRowInfo, sc, height, classNames }: Props) {
  const { inView, ref } = useInView({
    threshold: 0,
    triggerOnce: false,
  })
  const totalDuration = useAppSelector(getUpdateInterval)

  const currentValue = useValueTransition({
    prevAmount: jackpotRowInfo.prevAmount || 0,
    amount: jackpotRowInfo.amount,
    updatedAt: jackpotRowInfo.updatedAt,
    updateInterval: totalDuration,
    inView,
    sc,
  })

  return (
    <div ref={ref} className={classNames}>
      <RollNumber height={height} numberToRoll={currentValue} />
    </div>
  )
}

export default TransitionNumber

import React from 'react'
import Image from 'next/image'
import classes from './styles.module.scss'
import type { JackpotTotalsCode } from '@patrianna/shared-patrianna-types/store/JackpotsModule'
import type { Currencies } from '@patrianna/shared-patrianna-types/store/CurrenciesModule'
import { useTranslation } from 'app/context/translations'

type Props = {
  code: JackpotTotalsCode
  count: number
  currency: Currencies
}

const WinBadge = ({ code, count, currency }: Props) => {
  const t = useTranslation()
  const getWonCountLabel = () => {
    switch (count) {
      case 1:
        return 'Once'
      case 2:
        return 'Twice'
      default:
        return `${count} X`
    }
  }

  return (
    <div className={classes.winContainer}>
      <p className={classes.winText}>{t('jackpots_home_banners.win_badge_label')}</p>
      <Image
        src={`${process.env.IMG_ORIGIN}/jackpots/win-${currency.toLowerCase()}-${code}.png`}
        alt='win badge'
        className={classes.winImage}
        priority
        width={'100'}
        height={'73'}
      />
      <p className={classes.winCount}>{getWonCountLabel()}</p>
    </div>
  )
}

export default WinBadge

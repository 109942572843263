import cx from 'classnames'
import classes from './styles.module.scss'

type RainbowBackdropProps = {
  className?: string
  classNameBackdrop?: string
}

export default function RainbowBackdrop({ className }: RainbowBackdropProps) {
  return <div className={cx(classes.block, className)} />
}

import { useAppDispatch } from 'src/store/hooks'
import { openDialog } from 'store/modules/dialog/actions'
import BannerTile from 'components/BannerTile'
import { generateBannerUrl } from '../utils/generateBannerUrl'

export default function WelcomeEmailBanner() {
  const dispatch = useAppDispatch()
  const bannerUrl = generateBannerUrl('banners/check-email-banner/source.png')

  const openWelcomeEmailDialog = () => {
    dispatch(openDialog({ modalName: 'WELCOME_EMAIL_SEND_DIALOG' }))
  }

  return (
    <BannerTile
      onClick={openWelcomeEmailDialog}
      src={bannerUrl}
      alt='welcome-email-banner'
      dataTest='welcome-email-banner'
    />
  )
}

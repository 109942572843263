import cx from 'classnames'
import RainbowBorder from 'components/RainbowBorder'
import classes from './styles.module.scss'

type Props = {
  icon?: string
  selected: boolean
  className?: string
  label: string
  onClick: () => void
}

function Pill(props: Props) {
  const { label, onClick, selected, className, icon = 'top', ...rest } = props

  return (
    <RainbowBorder height='38px' width='max-content' borderRadius='200px' enabled={selected}>
      <div
        className={cx(classes.pillRoot, className, { [classes.selectedPill]: selected })}
        onClick={onClick}
        {...rest}
      >
        <div className={classes.pillIcon}>
          <img className={classes.pillIcon} src={`${process.env.IMG_ORIGIN}/menu/${icon}.svg`} alt='icon' />
        </div>
        <div className={classes.pillLabel}>{label}</div>
      </div>
    </RainbowBorder>
  )
}

export default Pill

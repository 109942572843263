import classes from './styles.module.scss'
import type { ReactElement } from 'react'
import getLastWinText from 'containers/Jackpots/components/LastWin/utils'

type Props = {
  lastWinAt: number
}

export default function LastWin({ lastWinAt }: Props): ReactElement {
  const lastWinAtText = getLastWinText(new Date().getTime(), lastWinAt)

  return <div className={classes.root}>{lastWinAtText}</div>
}

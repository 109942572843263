import { useCallback, useEffect, useState } from 'react'
import builder from '@builder.io/react'
import { useAuth } from 'app/context/auth'
import { useCountryConfig } from 'app/context/country'
import { getBuilderFilteredData } from 'app/utils/getBuilderFilteredData'

export default function useBuilderCMSData(builderModel: string) {
  const [builderData, setBuilderData] = useState([])
  const { isScEnabled } = useAuth()
  const enabledCurrency = isScEnabled ? 'SC' : 'GC'
  const { countryCode } = useCountryConfig()

  const fetchData = useCallback(async () => {
    try {
      const items = await builder.getAll(builderModel)

      setBuilderData(getBuilderFilteredData(items, countryCode, enabledCurrency))
    } catch (error) {
      console.log('fetchData Error', error)
    }
  }, [builderModel, countryCode, enabledCurrency])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return builderData
}

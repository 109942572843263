import type { ShopOffer } from '@patrianna/shared-patrianna-types/store/ShopModule'
import { buyShopOffer } from '@patrianna-payments/shared-store/payments/store/middleware-actions'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { getBannerOffersSelector } from 'store/modules/shop/selectors'
import BannerTile from 'components/BannerTile'
import { useRouter } from 'app/context/navigation'
import { generateBannerUrl } from '../utils/generateBannerUrl'

export default function FirstTimeSCOffers() {
  const router = useRouter()
  const offers = useAppSelector(getBannerOffersSelector)
  const dispatch = useAppDispatch()

  const openFirstSweep = (offer: ShopOffer) => {
    dispatch(buyShopOffer({ offer, feature: 'banner', routerPush: router.push as () => void }))
  }

  return (
    <>
      {offers.map((offer) => {
        const bannerUrl = generateBannerUrl(offer.bannerImageUrl)

        return (
          <BannerTile
            onClick={() => openFirstSweep(offer)}
            src={bannerUrl}
            alt='shop banner'
            key={offer.code}
            dataTest={`first-time-sc-offer-banner_${offer.code}`}
          />
        )
      })}
    </>
  )
}
